import { useMemo } from 'react';

import usePermission from 'hooks/usePermission';
import { IGlobalSearchModule } from 'services/common/common.modal';
import { deepEqual } from 'utils/commonUtils';

import { defaultSearchConfig } from './config';

interface ILocalRecentlyViewedDataType {
  module: IGlobalSearchModule;
  data: object[];
}

interface IURecentlyViewedReturnType {
  getModuleRecentlyViewedData: () => void;
  setRecentlyViewedData: (data: unknown, module: IGlobalSearchModule) => void;
  getRecentlyViewedData: (module: IGlobalSearchModule) => ILocalRecentlyViewedDataType[];
  getAllRecentlyViewedData: () => ILocalRecentlyViewedDataType[];
  key: string;
}

const KEY = 'search-recently-viewed';

export const useRecentlyViewed = (): IURecentlyViewedReturnType => {
  const { isPermissionAvailable } = usePermission();

  const allowedModuleIds = useMemo(() => {
    return defaultSearchConfig
      .filter(({ access }) => isPermissionAvailable(access))
      .map(({ id }) => id);
  }, [isPermissionAvailable]);

  const getAllowedLocalData = (): ILocalRecentlyViewedDataType[] => {
    const recentlyViewdItems: ILocalRecentlyViewedDataType[] = JSON.parse(
      localStorage.getItem(KEY) ?? '[]',
    );

    if (recentlyViewdItems?.length) {
      return recentlyViewdItems.filter(({ module }) => allowedModuleIds.includes(module));
    }

    return recentlyViewdItems;
  };
  const getLocalData = (): ILocalRecentlyViewedDataType[] => {
    return JSON.parse(localStorage.getItem(KEY) ?? '[]');
  };
  const getModuleRecentlyViewedData = (): void => {};
  const setRecentlyViewedData = (data: unknown, module: IGlobalSearchModule): void => {
    const localData = getLocalData();

    const payload = {
      module,
      data,
    };

    const isAlreadyPresent = localData.findIndex((d) => deepEqual(d, payload));
    if (isAlreadyPresent === -1) {
      localData.unshift(payload as ILocalRecentlyViewedDataType);
    } else {
      const d = localData.splice(isAlreadyPresent, 1);
      localData.unshift(d[0]);
    }

    if (localData.length > 50) {
      localData.length = 50;
    }

    localStorage.setItem(KEY, JSON.stringify(localData));
  };
  const getRecentlyViewedData = (module: IGlobalSearchModule): ILocalRecentlyViewedDataType[] => {
    return getAllowedLocalData().filter(({ module: m }) => module === m) ?? [];
  };

  const getAllRecentlyViewedData = (): ILocalRecentlyViewedDataType[] => {
    return getAllowedLocalData();
  };

  return {
    getModuleRecentlyViewedData,
    setRecentlyViewedData,
    getRecentlyViewedData,
    getAllRecentlyViewedData,
    key: KEY,
  };
};
