import React from 'react';

import { Typography, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Command } from 'cmdk';

import AssetStatusBadge from 'components/assets/asset-status-badge/AssetStatusBadge';
import JobStatusBadge from 'components/jobs/job-status-badge/JobStatusBadge';
import { getJobType } from 'features/jobs/helper';
import { AssetStatus, IAssetListDetails } from 'services/asset-service/asset.modal';
import { IGlobalSearchModule } from 'services/common/common.modal';
import { JobDetails } from 'services/job-service/job.modal';
import { IPartsList } from 'services/parts/parts.modal';
import {
  ILocationDetails,
  ILocationGroupDetails,
  IUserDetailsResponse,
  UserType,
} from 'services/settings-service/settings.modal';
import { ITelematicDevices } from 'services/telematics/telematics.modal';
import { remCalc } from 'theme/utils';

import SearchRow from './SearchRow';

interface ModuleListViewProps {
  module: IGlobalSearchModule;
  handleRedirection: (value: string, data: unknown, m: IGlobalSearchModule) => void;
  details: unknown;
  isRecentView?: boolean;
}

const moduleTextMapper = {
  [IGlobalSearchModule.assets]: 'Assets',
  [IGlobalSearchModule.telematics]: 'Telematics',
  [IGlobalSearchModule.jobs]: 'Jobs',
  [IGlobalSearchModule.organisation_locations]: 'Locations',
  [IGlobalSearchModule.parts]: 'Parts',
  [IGlobalSearchModule.users]: 'Users',
};

function ModuleListView({
  module,
  handleRedirection,
  details,
  isRecentView,
}: ModuleListViewProps): JSX.Element {
  const theme = useTheme();

  const mobileMatches = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  const renderModule = (): JSX.Element | null => {
    if (!isRecentView || mobileMatches) {
      return null;
    }
    return (
      <Box
        sx={{
          height: '24px',
          ml: 'auto',
          p: '4px 16px',
          background: '#F5F5F5',
          borderRadius: '32px',
          color: '#141414',
          fontSize: remCalc(12),
          fontWeight: 500,
        }}
      >
        in {moduleTextMapper[module as keyof typeof moduleTextMapper]}
      </Box>
    );
  };

  const getModuleRowView = (): JSX.Element => {
    if ((details as { showAllQuery: string })?.showAllQuery) {
      const { showAllQuery } = details as { showAllQuery: string };
      return (
        <Command.Item
          keywords={[showAllQuery]}
          value={`${module} ${showAllQuery}`}
          onSelect={(): void => handleRedirection(showAllQuery, null, module)}
        >
          <>
            <SearchRow desc=''>
              <Typography>{showAllQuery}</Typography>
            </SearchRow>
            {renderModule()}
          </>
        </Command.Item>
      );
    }

    switch (module) {
      case IGlobalSearchModule.assets: {
        const { type, externalId, status, assetId } = details as IAssetListDetails;
        return (
          <Command.Item
            key={externalId}
            keywords={[externalId]}
            value={`${externalId} ${assetId}`}
            onSelect={(): void =>
              handleRedirection(
                assetId,
                { assetId, status, externalId, type },
                IGlobalSearchModule.assets,
              )
            }
          >
            <>
              <SearchRow desc={type?.name}>
                <>
                  <Typography>{externalId}</Typography>
                  <Box
                    sx={{
                      '& .MuiChip-root': {
                        height: '24px',
                        fontWeight: 500,
                      },
                    }}
                  >
                    {status ? <AssetStatusBadge status={status as unknown as AssetStatus} /> : ''}
                  </Box>
                </>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.telematics: {
        const { id, serial, providerId, structure } = details as ITelematicDevices;
        return (
          <Command.Item
            key={id}
            value={serial}
            keywords={[serial]}
            onSelect={(): void =>
              handleRedirection(
                id,
                { id, serial, providerId, structure },
                IGlobalSearchModule.telematics,
              )
            }
          >
            <>
              <SearchRow desc=''>
                <Box
                  flexDirection='column'
                  sx={{
                    display: 'flex',
                    gap: '4px',
                  }}
                >
                  <Typography fontSize={14} color='#141414'>
                    {serial}
                  </Typography>
                  <Typography fontSize={12} color='#424242'>
                    {providerId}
                  </Typography>
                  <Typography fontSize={12} color='#A3A3A3'>
                    {structure?.name}
                  </Typography>
                </Box>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.jobs: {
        const { jobId, jobNumber, status, jobType } = details as JobDetails;
        return (
          <Command.Item
            key={jobNumber}
            value={jobNumber}
            keywords={[jobNumber]}
            onSelect={(): void =>
              handleRedirection(
                jobId,
                { jobId, jobNumber, status, jobType },
                IGlobalSearchModule.jobs,
              )
            }
          >
            <>
              <SearchRow
                desc={
                  <Typography
                    sx={{
                      fontSize: '12px',
                      color: '#A3A3A3',
                      lineHeight: '16px',
                      '&  p': {
                        color: '#A3A3A3',
                        fontWeight: 400,
                      },
                    }}
                  >
                    {getJobType(jobType, undefined, true)}
                  </Typography>
                }
              >
                <>
                  <Typography sx={{ fontSize: '14px' }}>{jobNumber}</Typography>
                  <Box
                    sx={{
                      '& .MuiChip-root': {
                        height: '24px',
                        fontWeight: 500,
                      },
                    }}
                  >
                    {status ? <JobStatusBadge status={status} /> : '-'}
                  </Box>
                </>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.parts: {
        const { name, partId, categories } = details as IPartsList;
        return (
          <Command.Item
            key={partId}
            value={name}
            keywords={[name]}
            onSelect={(): void =>
              handleRedirection(partId, { name, partId, categories }, IGlobalSearchModule.parts)
            }
          >
            <>
              <SearchRow desc={categories.map((c) => c.name).join(', ')}>
                <Typography>{name}</Typography>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.organisation_locations: {
        const { name, locationId, organisation, address } = details as ILocationDetails;
        return (
          <Command.Item
            key={locationId}
            value={name}
            keywords={[name]}
            onSelect={(): void =>
              handleRedirection(
                locationId,
                { name, locationId, organisation, address },
                IGlobalSearchModule.organisation_locations,
              )
            }
          >
            <>
              <SearchRow desc={address?.country ?? '-'}>
                <Typography>{name}</Typography>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.location_groups: {
        const { name, locationGroupId } = details as ILocationGroupDetails;
        return (
          <Command.Item
            key={locationGroupId}
            value={name}
            keywords={[name]}
            onSelect={(): void =>
              handleRedirection(
                locationGroupId,
                { name, locationGroupId },
                IGlobalSearchModule.location_groups,
              )
            }
          >
            <>
              <SearchRow desc=''>
                <Typography>{name}</Typography>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      case IGlobalSearchModule.users: {
        const { name, email, userId, organisation, userType } = details as IUserDetailsResponse;
        return (
          <Command.Item
            key={userId}
            value={`${name} ${email}`}
            keywords={[name, email]}
            onSelect={(): void =>
              handleRedirection(
                userId,
                { name, email, userId, organisation, userType },
                IGlobalSearchModule.users,
              )
            }
          >
            <>
              <SearchRow desc={userType === UserType.operator ? 'Operator' : 'User'}>
                <Typography>
                  {name} ({email})
                </Typography>
              </SearchRow>
              {renderModule()}
            </>
          </Command.Item>
        );
      }

      default:
        return <Box />;
    }
  };

  return getModuleRowView();
}
export default ModuleListView;
